
import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import {ElForm} from "element-ui/types/form";
import api from "@/api";
import AbSelect from "@/components/AbSelect/index.vue";
import {getCapitalPropertyList, getCurrencyType, getPayTypes} from "@/utils/enumToSelectItems";
import ProjectSelect from "@/components/ProjectSelect/index.vue";

import {
  CapitalIncomeRecordFromDonationCreateOrUpdateDto,
  CapitalIncomeRecordFromDonationDetailCreateOrUpdateDto,
  CapitalProperty,
  CurrencyType,
  DataDictionaryDto,
  PayType
} from "@/api/appService";
import validate from "@/utils/validate";

@Component({
  components: {
    AbSelect,
    ProjectSelect
  },
  filters: {}
})


export default class EditDonationIncome extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({required: true})
  dataId!: number;

  defaultData: CapitalIncomeRecordFromDonationCreateOrUpdateDto = {
    incomeDate: undefined,
    donatorName: undefined,
    id: 0,
    incomeType: undefined,
    payBankAccount: undefined,
    bankAccount: undefined,
    totalIncomeMoney: undefined,
    details: [],
  };
  activeName = "first"; //显示第一个选项卡
  payTypeList = getPayTypes();
  capitalPropertyList = getCapitalPropertyList();
  currencyTypeList = getCurrencyType();
  payBankAccountList: DataDictionaryDto[] = [];

  show = false;

  form: CapitalIncomeRecordFromDonationCreateOrUpdateDto = {...this.defaultData};


  get dialogTitle() {
    return this.form!.id ? "编辑" : "新增";
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {

      if (this.dataId) {
        await api.capitalIncomeRecordFromDonation.get({id: this.form!.id}).then(res => {
          this.form = {...res};
        });
      } else {
        this.form = {...this.defaultData};
      }
    } else {
      this.form = {...this.defaultData};
    }

    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  created() {
    this.initDefaultData();
  }

  initDefaultData() {
    //付款账号
    api.dataDictionary
      .getDataDictionaryListByKey({key: "PayBankAccount"})
      .then(res => {
        this.payBankAccountList = res.items!;
      });
  }


  addDetail() {
    const newItem = {
      capitalProperty: CapitalProperty.Finite,
      currencyType: CurrencyType.RMB,
      projectId: undefined,
      money: 0,
      remark: ''
    } as CapitalIncomeRecordFromDonationDetailCreateOrUpdateDto;
    if (this.form.details) {
      this.form.details!.push(newItem)
    } else {
      this.form.details = [newItem];
    }

  }

  handleRemoveDetail(index: number) {
    const result = [] as CapitalIncomeRecordFromDonationDetailCreateOrUpdateDto[];
    for (let i = 0; i < this.form.details!.length; i++) {
      if (i === index)
        continue;

      result.push(this.form.details![i]);
    }
    this.form.details = result;


  }

  async save() {
    console.log(this.form);
    this.dataForm.clearValidate()
    this.dataForm.validate(async (valid: boolean) => {
      if (valid) {
        if ("Material" == this.form.incomeType) {
          //Pass
        } else {
          if ("" == this.form.bankAccount || undefined === this.form.bankAccount) {
            //alert("收款账号必须选择");
            return
          } else {
            //Pass
          }
        }

        if (this.form!.id) {
          await api.capitalIncomeRecordFromDonation.update({body: this.form});
        } else {
          await api.capitalIncomeRecordFromDonation.create({body: this.form});
        }
        this.show = false;
        this.$message.success("更新成功");
        this.$emit("onSave");
      }
    });
  }

  cancel() {
    this.show = false;
  }


  roleRule = {
    incomeDate: [
      {
        required: true,
        message: "捐赠日期必填",
        trigger: "change"
      }
    ],
    donatorName: [
      {
        required: true,
        message: "捐赠方姓名必填",
        trigger: "change"
      }
    ],
    bankAccount: [
      {
        required: true,
        message: '账号必填',
        trigger: ['change', 'blur']
      },
    ],
    capitalProperty: [
      {
        required: true,
        message: "捐赠性质必填",
        trigger: ['change', 'blur']
      }
    ],
    currencyType: [
      {
        required: true,
        message: "币种必填",
        trigger: ['change', 'blur']
      }
    ],
    money: [
      {
        required: true,
        trigger: "change",
        validator: validate.validMoney
      }
    ],
    remark: [
      {
        required: true,
        message: "必填",
        trigger: "change"
      }
    ],
  };
}
