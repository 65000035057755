
import { Component, Ref, Vue } from "vue-property-decorator";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditButtons from "@/components/AuditButtons/index.vue";
import { AuditBodyDto } from "@/utils/customDto";
import {
  CapitalIncomeRecordFromDonationDetailDto,
  CapitalIncomeRecordFromDonationDto,
  AttachmentHostType,
  AuditFlowScope
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
@Component({
  name: "CapitalIncomeRecordFromDonationDetail",
  components: {
    AttachmentsView,
    ExportWord,
    AuditButtons
  }
})
export default class CapitalIncomeRecordFromDonationDetail extends Vue {
  @Ref() auditButtons!: AuditButtons;
  detailId?: number;
  detail: CapitalIncomeRecordFromDonationDto = {};
  selector: string = "table-detail";
  filename: string = "资金捐赠详情";
  styles: string = "";
  auditParams: AuditBodyDto = {
    hostId: undefined,
    hostType: AuditFlowScope.CapitalIncomeRecordFromDonation,
    auditFlowId: undefined,
    auditStatus: undefined,
    audit: undefined
  };

  get hostTypeFile() {
    return AttachmentHostType.Internal_Project_Monthly_Summarize_Attachment;
  }

  created() {
    if (this.$route.params.id) {
      this.detailId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }
  // 获取详情
  async fetchDetail() {
    await api.capitalIncomeRecordFromDonation
      .get({ id: this.detailId })
      .then(res => {
        let resData = res;
        this.detail = { ...resData };
        this.auditParams!.hostId = res.id + "";
        this.auditParams!.auditFlowId = res.auditFlowId;
        this.auditParams!.auditStatus = res.auditStatus;
        this.auditParams!.audit = res.audit;
        if (res.auditFlowId) {
          (this.auditButtons as any).fetchCanAudit();
        }
      });
  }

  changeAudit() {
    this.fetchDetail();
  }
  fetchAuditData() {
    return api.capitalIncomeRecordFromDonation.audit;
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
